document.addEventListener('DOMContentLoaded', (e) => {

    // scroll-triggered related posts block
    let blockFn = (entries) => entries.forEach(entry => {
        if (entry.isIntersecting) {
            $(entry.target).addClass('animate');
        }
    });
    let observer = new IntersectionObserver(blockFn);
    document.querySelectorAll('.block--hero').forEach(el => observer.observe(el));

});